<template>
  <div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// @ is an alias to /src

export default {
  name: 'Main',
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
  },
  methods: {
    ...mapActions('users', [
      'checkTokenStatus',
    ]),
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (!this.hasValidToken) {
        this.$router.replace({name: 'Login', query: {redirect_route: 'Main'} })
      } else {
        this.$router.push({name: 'Session', query: {session_id: 645}})
      }
    })
  },
}
</script>
